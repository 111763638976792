import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "workbench" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      title: _ctx.$route.meta.title,
      "left-arrow": "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"])
  ]))
}